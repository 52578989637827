import React from 'react';
import { graphql } from 'gatsby';
import ListingPage from '../components/ListingPage';

export default ({ data: { cover, activities } }) => (
  <ListingPage
    cover={cover}
    list={activities}
    linkPrefix="aktivitaeten"
    title="Aktivitäten"
    subtitle="Sport im Urlaub"
  />
);

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "cycling.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    activities: allSanityActivity {
      totalCount
      edges {
        node {
          title
          _rawExcerpt
          price
          mainImage {
            asset {
              fluid(maxWidth: 2048) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
